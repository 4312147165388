@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  :root {
    --color-primary-50: 244 247 254;
    --color-primary-100: 233 239 253;
    --color-primary-200: 201 216 250;
    --color-primary-300: 168 193 247;
    --color-primary-400: 102 146 241;
    --color-primary-500: 37 99 235;
    --color-primary-600: 33 89 212;
    --color-primary-700: 22 59 141;
    --color-primary-800: 17 45 106;
    --color-primary-900: 11 30 71;
    --color-destructive-50: 254 246 246;
    --color-destructive-100: 253 236 236;
    --color-destructive-200: 251 208 208;
    --color-destructive-300: 249 180 180;
    --color-destructive-400: 244 124 124;
    --color-destructive-500: 239 68 68;
    --color-destructive-600: 215 61 61;
    --color-destructive-700: 143 41 41;
    --color-destructive-800: 108 31 31;
    --color-destructive-900: 72 20 20;
    --color-secondary-50: 248 251 255;
    --color-secondary-100: 241 247 255;
    --color-secondary-200: 221 234 255;
    --color-secondary-300: 200 222 255;
    --color-secondary-400: 158 197 255;
    --color-secondary-500: 117 172 255;
    --color-secondary-600: 105 155 230;
    --color-secondary-700: 70 103 153;
    --color-secondary-800: 53 77 115;
    --color-secondary-900: 35 52 77;
  }

  .dark {
    --color-primary-50: 244 244 245;
    --color-primary-100: 233 234 236;
    --color-primary-200: 201 202 206;
    --color-primary-300: 168 170 177;
    --color-primary-400: 103 107 119;
    --color-primary-500: 38 43 60;
    --color-primary-600: 34 39 54;
    --color-primary-700: 23 26 36;
    --color-primary-800: 17 19 27;
    --color-primary-900: 11 13 18;
    --color-shade-50: 244 244 245;
    --color-shade-100: 233 234 236;
    --color-shade-200: 201 202 206;
    --color-shade-300: 168 170 177;
    --color-shade-400: 103 107 119;
    --color-shade-500: 38 43 60;
    --color-shade-600: 34 39 54;
    --color-shade-700: 23 26 36;
    --color-shade-800: 17 19 27;
    --color-shade-900: 11 13 18;
    --color-card-50: 244 245 246;
    --color-card-100: 234 234 236;
    --color-card-200: 202 203 208;
    --color-card-300: 170 172 179;
    --color-card-400: 106 110 123;
    --color-card-500: 42 48 66;
    --color-card-600: 38 43 59;
    --color-card-700: 25 29 40;
    --color-card-800: 19 22 30;
    --color-card-900: 13 14 20;
  }
}
